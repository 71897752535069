import React, { useState, useEffect } from "react"
import Image from "./image"
import TextBox from "./textbox"

const backgrounds = [
  '#2700FF',
  '#C1C1C1',
  '#111111'
]
export default function Application({ images }) {
  const [index, changeIndex] = useState(0)
  const [intervalTime, changeInterval] = useState(10000)
  const [background, changeBackground] = useState(0)
  const [caption, changeCaptionUse] = useState(false)

  useEffect(() => {
    const interval = setInterval(
      () => changeIndex(index >= images.length - 1 ? 0 : index + 1),
      intervalTime
    )
    return () => clearInterval(interval)
  }
  )
  return (
    <div className="cont">
      <div className="option-block">
        <div className="option title">
          jpegs.xyz
        </div>
        <TextBox
          header={""}
          onClick={ () => {
            let newBackground = background + 1
            if(newBackground >= backgrounds.length){
              newBackground = 0
            }
            changeBackground(newBackground)
          } }
          content={`background [${ background }]`}
        />
        <TextBox
          header={""}
          onClick={() =>
            changeInterval(
                intervalTime === 10000
                ? 20000
                : intervalTime === 20000
                ? 30000
                : intervalTime === 20000
                ? 30000
                : intervalTime === 30000
                ? 60000
                : 10000
            )
          }
          content={`timing [${intervalTime / 1000}]`}
        />
        <TextBox
          header={""}
          onClick={() => changeCaptionUse(!caption)}
          content={caption ? "captions [on]" : "captions [off]"}
        />
      </div>
      <div className="caption-block">
        {caption && (
          <TextBox
            content={ `${ images[index].relativePath } — ${ images[index].prettySize.toLowerCase() }` }
          />
        )}
      </div>
      <div className="option-bar"></div>
      <main
        style={{
          backgroundColor: backgrounds[background]
        }}
        className="flex flex-center">
        <div className="jpeg-block" onClick={()=> changeIndex(index >= images.length - 1 ? 0 : index + 1)}><div className="img">{<Image src={images[index].childImageSharp.id} />}</div></div>
      </main>
    </div>
  )
}



