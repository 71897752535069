import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({src}) => {
  return <StaticQuery
    query={graphql`
     query {
            allImageSharp {
              edges {
                node {
                  id
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
    `}
    render={data =>{

      const fluid = data.allImageSharp.edges.find((element) =>{
        return element.node.id === src
       })
      return fluid ? <Img fluid={fluid.node.fluid}  /> : null
    } }
  />
}

export default Image
